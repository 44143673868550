import React from 'react';
import { FC, memo } from 'react';
import { RowMain } from '../../layout/RowMain';

type Props = {};

export const TextPage: FC<Props> = memo(({ children }) => {
	return (
		<div className="b-text">
			<RowMain>{children}</RowMain>
		</div>
	);
});

TextPage.displayName = 'TextPage';
