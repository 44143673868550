import React from 'react';
import { Layout } from '../components/ui/Layout';
import { Text } from '../components/ui/typography/Text';
import { TextPage } from '../components/ui/text/TextPage';
import { Link } from 'gatsby';
import { TextHeader } from '../components/layout/TextHeader';
import loadable from '@loadable/component';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Helmet = loadable(() => import('react-helmet'));

export default () => (
	<Layout>
		<Helmet>
			<title>PageSpeed.cz - Zásady užívání „cookies“</title>
		</Helmet>
		<TextHeader headline="Zásady užívání „cookies“" />
		<TextPage>
			<h2>Cookies na našem webu</h2>
			<Text>
				Na internetových stránkách <Link to="/">https://pagespeed.cz/</Link> (dále jen „Stránky“) užíváme tzv.
				soubory cookies. Tímto a <Link to="/privacy/">Zásadami ochrany osobních údajů</Link> o tom plníme naši
				informační povinnost v souladu s nařízením Evropského parlamentu a Rady (EU) č. 2016/679, o ochraně
				fyzických osob v souvislosti se zpracováním osobních údajů (dále jen „GDPR“).
			</Text>
			<h2>Co jsou „cookies“?</h2>
			<Text>
				Cookies jsou malé textové soubory, které mohou být používány webovými stránkami, aby učinily uživatelský
				zážitek více efektivní. Cookies neznamenají žádné nebezpečí pro počítač jako takový. Přesto mají cookies
				význam pro ochranu soukromí. Navštívený web si totiž může ukládat do cookies informace, které o
				návštěvníkovi zjistí a může tak postupně zjišťovat zájmy konkrétního návštěvníka.
			</Text>
			<Text>
				Právní normy uvádí, že můžeme ukládat cookies na vašem zařízení, pokud jsou nezbytně nutné pro provoz
				této stránky. Pro všechny ostatní typy cookies potřebujeme váš souhlas. Stránky používají různé typy
				cookies. Některé cookies jsou umístěny službami třetích stran, které se na nich objevují.
			</Text>
			<h2>Jak cookies používáme?</h2>
			<h3>Nutné cookies</h3>
			<Text>
				Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní fungování jejich základních
				vlastností jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže
				bez těchto cookies správně fungovat.
			</Text>
			<Text>
				<i>Stránky neužívají žádné cookies tohoto typu.</i>
			</Text>
			<h3>Statistické a jiné cookies</h3>
			<Text>
				Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají
				webové stránky. Anonymně sbírají a sdělují informace. Jiné cookies pak mohou pomoci, aby si webová
				stránka zapamatovala informace, které mění, jak se webová stránka chová nebo jak vypadá či zobrazit
				sdělení, které je relevantní a zajímavé pro jednotlivého uživatele.
			</Text>
			<table className="table-responsive">
				<tbody>
					<tr>
						<td data-label="Aplikace:">
							<span className="u-color-blue-light">Google Analytics</span>
						</td>
						<td data-label="Příkady cookies:">
							<strong className="u-hide@mdDown">Příklad: </strong> _ga, _gid…
						</td>
						<td data-label="Účel:">
							<strong className="u-hide@mdDown">Účel: </strong> Měření návštěvnosti a e-commerce
						</td>
					</tr>
				</tbody>
			</table>
			<h2 className="h3">Souhlas s ukládáním cookies</h2>
			<Text>Většina prohlížečů cookies automaticky akceptuje, pokud není prohlížeč nastaven jinak.</Text>
			<Text>
				<i>
					Svým nastavením prohlížeče a používáním Stránek výslovně souhlasíte s ukládáním nejen nutných
					souborů cookies.
				</i>
			</Text>
			<Text>
				Použití takových cookies můžete kdykoliv omezit nebo zablokovat v nastavení svého webového prohlížeče
				dle Vaší libosti. Informace o nastavení konkrétního prohlížeče naleznete na těchto uvedených adresách
			</Text>
			<ul>
				<li>
					Chrome:{' '}
					<OutboundLink href="http://support.google.com" target="_blank" rel="noopener noreferrer">
						support.google.com
					</OutboundLink>
				</li>
				<li>
					Opera:{' '}
					<OutboundLink href="http://help.opera.com" target="_blank" rel="noopener noreferrer">
						help.opera.com
					</OutboundLink>
				</li>
				<li>
					Firefox:{' '}
					<OutboundLink href="http://support.mozilla.org" target="_blank" rel="noopener noreferrer">
						support.mozilla.org
					</OutboundLink>
				</li>
				<li>
					MSIE:{' '}
					<OutboundLink href="http://support.microsoft.com" target="_blank" rel="noopener noreferrer">
						support.microsoft.com
					</OutboundLink>
				</li>
				<li>
					Edge:{' '}
					<OutboundLink href="http://support.microsoft.com" target="_blank" rel="noopener noreferrer">
						support.microsoft.com
					</OutboundLink>
				</li>
				<li>
					Safari:{' '}
					<OutboundLink href="http://support.apple.com" target="_blank" rel="noopener noreferrer">
						support.apple.com
					</OutboundLink>
				</li>
			</ul>
			<Text>
				Cookies budeme ze Stránek automaticky mazat nejpozději do 13 měsíců od jejich posledního využití.
			</Text>
			<Text>
				Viz také <Link to="/privacy">zásady ochrany osobních údajů</Link>.
			</Text>
		</TextPage>
	</Layout>
);
