import React, { FC, ReactNode } from 'react';

interface Props {
	headline: ReactNode;
}

export const TextHeader: FC<Props> = ({ headline }) => (
	<section className="b-headline b-headline--text u-bg-color-blue">
		<div className="row-main">
			<h1 className="b-headline__title u-color-white">{headline}</h1>
			<img src="/img/textHeaderIllustration.svg" alt="Ilustrace" width="283" height="137" />
		</div>
	</section>
);
